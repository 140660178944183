.faq-container {
  color: #7a6a5d;
  max-width: 1200px; /* Limits the width of the container */
  margin: 0 auto; /* Centers the container horizontally */
  padding: 20px; /* Adds padding inside the container */
  display: flex; /* Enables flexbox layout */
  flex-direction: column; /* Stacks child elements vertically */
  gap: 15px; /* Space between child elements */
}

.faq-item {
  padding: 10px 0;
  border-bottom: 1px solid #ccc; /* Adds a separator between items */
}

.faq-question {
  font-size: 18px; /* Font size for the question */
  padding: 10px 0; /* Padding for the question */
  font-weight: bold;
}

.faq-answer {
  font-size: 16px; /* Font size for the answer */
  padding: 10px 0; /* Padding for the answer */
  margin-top: 5px; /* Space above the answer */
}

.dress-inspo-img {
  width: 20%;
  padding: 5px;
}

.dress-inspo-img-2 {
  width: 30%;
  padding: 5px;
}

.dress-inspo-div {
  display: flex;
}
