/* Base styles for navigation */
.navbar {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  color: #7a6a5d;
  font-weight: 600;
  width: 100%;
}

.nav-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000; /* Ensures it appears above other content */
  background-color: white;
  border-bottom: 1px solid #7a6a5d;
}

.nav-links {
  display: flex;
  list-style-type: none;
}

.nav-links li {
  margin-left: 40px;
  color: #7a6a5d;
  text-decoration: none;
}

.nav-links a {
  color: #7a6a5d;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Hamburger menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  font-size: 2rem;
}

/* Responsive adjustments */
@media (max-width: 842px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 50px;
    left: 0;
    text-align: right;
    padding-left: 0;
    border-bottom: 1px solid #7a6a5d;
  }

  .nav-links.active {
    display: flex;
  }

  .hamburger {
    display: flex;
  }

  .nav-links li {
    margin: 4px 10px;
    font-size: 1.5rem;
  }
}

.navbar .blueButton {
  font-size: 1.5rem;
  padding: 10px;
  padding-left: 55px;
  padding-right: 55px;
}
