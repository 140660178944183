.table {
  /* display: flex; */
  /* border: 1px solid #7a6a5d; */
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
}

.table-header {
  font-weight: bold;
  background-color: #35cbdd;
  color: white;
}

.table-row {
  display: flex;
  text-align: center;
}

.table-name {
  width: 50%;
  border: 1px solid #7a6a5d;
}

.table-status {
  width: 50%;
  border: 1px solid #7a6a5d;
}

.table-status select {
  width: 100%;
  border: none;
  text-align: center;
  height: 100%;
  color: #7a6a5d;
  background-color: transparent;
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
}

.firebase-message {
  height: 50px;
}

.firebase-status {
  opacity: 1; /* Fully visible by default */
  transition: opacity 3s ease-in-out;
}

.fade-out {
  animation: fadeOut 3s ease-in-out forwards;
}
.hidden {
  opacity: 0;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.admin-rsvp-status {
  margin-top: 2rem;
  padding: 1rem;
}

.rsvp-status-columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.rsvp-status-columns > div {
  width: 30%;
}

.rsvp-status-columns h3 {
  margin-bottom: 0.5rem;
  color: #333;
}

.guest-status {
  margin: 0.3rem 0;
}

.guest-status.unconfirmed {
  color: #f57c00; /* Orange for unconfirmed */
}

.guest-status.attending {
  color: #2e7d32; /* Green for attending */
}

.guest-status.not-attending {
  color: #d32f2f; /* Red for not attending */
}

.messages-section {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.message-entry {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
}

.message-entry p {
  margin: 0.5rem 0;
}

.message-entry strong {
  font-weight: bold;
}

.message-entry:last-child {
  border-bottom: none;
}

.rsvp-status-columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; /* Add space between the status headers and the guest lists */
}

.column {
  width: 30%; /* Adjust the width of each column */
}

.column h3 {
  text-align: center;
  font-weight: bold;
}

.guest-status {
  text-align: center;
  padding: 5px;
}

.guest-status.unconfirmed {
  background-color: #ffe0b2;
}

.guest-status.attending {
  background-color: #c8e6c9;
}

.guest-status.not-attending {
  background-color: #ffccbc;
}

.guest-email {
  margin-bottom: 10px;
}

.email-group-box {
  border: 2px solid #000; /* Add a border around the email and its guests */
  padding: 10px;
  margin-bottom: 20px; /* Space between each email group */
  border-radius: 8px; /* Optional: Adds rounded corners */
}

.guest-email {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px; /* Add space below the email */
  text-align: center;
}

.rsvp-status-columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; /* Space between the status headers and the guest lists */
}

.column {
  width: 30%; /* Adjust the width of each column */
}

.column h4 {
  text-align: center;
  font-weight: bold;
}

.guest-status {
  text-align: center;
  padding: 5px;
}

.guest-status.unconfirmed {
  background-color: #ffe0b2;
}

.guest-status.attending {
  background-color: #c8e6c9;
}

.guest-status.not-attending {
  background-color: #ffccbc;
}
