.resort-imgs .img {
  max-width: 30%;
  margin-right: 1%;
  margin-left: 1%;
}

@media (min-width: 1024px) {
  .resort-imgs .img {
    max-width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
  .img-right {
    float: right;
  }
  .resort-imgs .img-1 {
    margin-left: 20px;
  }
  .resort-imgs .img-2 {
    margin-top: -50px;
    margin-right: 0;
  }
  .resort-imgs .img-3 {
    margin-top: -50px;
    margin-left: 70px;
  }
}

.itinerary-brown-bg {
  background-color: #edd6c4;
  padding: 20px;
  max-width: 100%;
  border: 1px solid #edd6c4;
  border-radius: 10px;
}

.itinerary-white-bg {
  background-color: white;
  border: 1px solid white;
  border-radius: 10px;
  padding: 5px;
}

.itinerary-img {
  width: 100%;
  max-width: 50px;
}

.itinerary-img-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itinerary-flex {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  color: #7a6a5d;
  align-items: center;
  justify-content: center;
}

.itinerary-txt {
  font-weight: bold;
  font-size: 0.7rem;
  text-align: center;
}

.itinerary-line {
  width: 0; /* No width to make it a line */
  height: 10px; /* Full height of the container */
  border-left: 2px dotted #7a6a5d; /* Dotted line style */
  /* justify-self: center; */
  margin: 10px auto; /* Center in flex container */
  display: flex; /* Allows it to align properly */
}

.itinerary-header {
  background-color: #35cbdd;
  padding-left: 10px;
}

.itinerary-header h2 {
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  font-style: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  width: 100%;
}

.venue-bg {
  background-image: url('../public/img/venue.jpg'); /* Use the correct path */
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

.padding-venue {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.col-venue {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .col-venue {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

.venue-brown-bg {
  background-color: #edd6c4;
  padding: 20px;
  max-width: 100%;
  border: 1px solid #edd6c4;
  border-radius: 10px;
  display: flex;
  height: 100%;
  box-sizing: border-box;
}
